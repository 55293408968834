import React from 'react';
import {Segment, Container, Icon, Divider, Button} from 'semantic-ui-react';

function App() {
  return (
    <Segment basic>
      <Container>
        <Icon size='massive' className='app-logo' name="cog" color='purple' />
        <Divider hidden/>
        <p>Site is under construction. Please check back soon!</p>
        <Button basic inverted icon='pencil' content='Get in Touch' color='purple'/>
      </Container>
    </Segment>
  );
}

export default App;
